import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Header = () => (
  <header className="wpi-nav nav--absolute">
    <div className="nav-wrapper">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="wpi__navbar">
              <div className="navbar-brand">
                <div className="navbar-logo">
                  <Link to="/">
                    <img
                      src="https://assets.wpinsight.com/logo.png"
                      alt="Brand logo"
                    />
                  </Link>
                </div>
              </div>
              <button className="navbar-toggler">
                <span className="wpi wpi-bar"></span>
              </button>
              <div className="navbar-collapse">
                <div className="navbar__nav-menu ml-auto mr-auto">
                  <div className="navbar__nav-menu__item">
                    <Link className="navbar__nav-menu__item__link" to="/">
                      Home
                    </Link>
                  </div>
                  <div className="navbar__nav-menu__item">
                    <a
                      href="https://dashboard.wpinsight.com/auth/registration"
                      target="_blank"
                      rel="noreferrer"
                      className="navbar__nav-menu__item__link nav__menu--get__started"
                    >
                      Get Started
                    </a>
                  </div>
                  <div className="navbar__nav-menu__item">
                    <a
                      href="https://wpdeveloper.net/support/"
                      target="_blank"
                      rel="noreferrer"
                      className="navbar__nav-menu__item__link"
                    >
                      Support
                    </a>
                  </div>
                  <div className="navbar__nav-menu__item">
                    <Link
                      className="navbar__nav-menu__item__link"
                      to="/terms-of-services"
                    >
                      Terms &amp; Condition
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
