/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
      </div>
      <div className="background__primary pb70 pb-md-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6">
              <div className="footer__widget footer__content">
                <div className="logo">
                  <img
                    src="https://assets.wpinsight.com/logo-white.png"
                    alt="Footer logo"
                  />
                </div>
                <p className="copyright__text">
                  &copy; Copyright 2020. Powered by{" "}
                  <a href="https://wpdeveloper.net/" target="_blank">
                    WPDeveloper
                  </a>
                </p>
                <div className="footer__social-link">
                  <a
                    href="https://twitter.com/wpdevteam"
                    target="_blank"
                    rel="noreferrer"
                    className="background__twitter"
                  >
                    <span className="wpi wpi-twitter"></span>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/wpdev"
                    target="_blank"
                    rel="noreferrer"
                    className="background__linkedin"
                  >
                    <span className="wpi wpi-linkedin"></span>
                  </a>
                  <a
                    href="https://www.instagram.com/wpdevelopernet/"
                    target="_blank"
                    rel="noreferrer"
                    className="background__instagram"
                  >
                    <span className="wpi wpi-instagram"></span>
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer__widget footer__useful__links">
                <h4>Features</h4>
                <ul className="footer__widget__menu">
                  <li>
                    <a href="#">Email Tracking</a>
                  </li>
                  <li>
                    <a href="#">Employee Tracking</a>
                  </li>
                  <li>
                    <a href="#">Time Tracking</a>
                  </li>
                  <li>
                    <a href="#">Attendence</a>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6">
              <div className="footer__widget footer__useful__links">
                <h4>Services</h4>
                <ul className="footer__widget__menu">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  {/* <li>
                    <a href="#">About Us</a>
                  </li> */}
                  <li>
                    <Link to="/terms-of-services">Terms</Link>
                  </li>
                  {/* <li>
                    <a href="#">Product</a>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="footer__widget footer__useful__links">
                <h4>Plan</h4>
                <ul className="footer__widget__menu">
                  <li>
                    <a href="#">Basic Plan</a>
                  </li>
                  <li>
                    <a href="#">Business Plan</a>
                  </li>
                  <li>
                    <a href="#">Pro Subscription</a>
                  </li>
                  <li>
                    <a href="#">Pages</a>
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <div className="col-lg-3 col-md-6 col-12">
              <div className="footer__widget">
                <h4>Newslater</h4>
                <div className="footer__newsletter">
                  <p>
                    Advanced Analytics & Insights for WordPress Plugin & Theme
                  </p>
                  <form action="#">
                    <input type="email" placeholder="Your email address" />
                    <button className="button button--sm secondary__button">
                      Subscription
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
